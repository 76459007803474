import React from 'react';
import styled from 'styled-components';
import {getOutlet} from 'reconnect.js';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import NavLink from '../../Components/NavLink';
import LogisticsTrackingButton from '../../Components/LogisticsTrackingButton';
import OverseaLogisticsInfo from '../../Components/OverseaLogisticsInfo';

const ConfigOutlet = getOutlet('config');

function renderCustomComponent(props) {
  const {name, data} = props;
  if (name === 'CART_CUSTOM_COLUMN') {
    if (data.item.product.labels?.includes('custom')) {
      return <div />;
    } else {
      return null;
    }
  }
  return null;
}

function renderCartEmpty() {
  return (
    <div className="window-container">
      <div className="header">
        <div />
        <div />
        <div />
      </div>
      <div className="content">
        <img src="/images/site-cartempty.png" alt="cartempty" />
        <h1>CART EMPTY</h1>
        <h2>您的購物車為空</h2>
        <p>購物車尚無商品！歡迎選購加入購物車！</p>
        <NavLink to="/products">
          <button>立刻購物去</button>
        </NavLink>
      </div>
    </div>
  );
}

function renderCustomLogisticsForm({
  logistics_type,
  logistics_subtype,
  getExtraData,
  setExtraData,
  disabled,
}) {
  if (logistics_type === 'OVERSEA') {
    return (
      <OverseaLogisticsInfo
        logistics_subtype={logistics_subtype}
        getExtraData={getExtraData}
        setExtraData={setExtraData}
        disabled={disabled}
      />
    );
  } else if (logistics_type === 'SELF_PICK') {
    return (
      <LogisticsInfo>
        <h3>自取地點：</h3>
        {ConfigOutlet.getValue().self_pick_address}
      </LogisticsInfo>
    );
  }
  return null;
}

export function getExtraCartPageProps() {
  return {
    stickyTop: 84,
    renderCartEmpty,
    renderCustomComponent,
  };
}

export function getExtraInfoPageProps() {
  return {
    stickyTop: 84,
    forceSameAsBuyer: true,
    enableInvoiceFeature: true,
    renderCustomLogisticsForm,
  };
}

export function getExtraReviewPageProps() {
  return {
    stickyTop: 84,
    enableInvoiceFeature: true,
    fetchFromCheckout: true,
    checkoutFormProps: {
      forceSameAsBuyer: true,
      renderCustomLogisticsForm,
    },
    renderCustomComponent,
  };
}

export function getExtraOrderPageProps() {
  function renderExtraPaymentInfo(order) {
    if (order.payment_subtype === Cart.PAYMENT_SUBTYPE.atm) {
      const {bank_code, bank_account, pay_deadline} = order;

      if (!bank_account) {
        return null;
      } else {
        return (
          <OrderExtraPaymentInfo>
            <div>銀行代碼 {bank_code}</div>
            <div>虛擬帳號 {bank_account}</div>
            <div>繳款期限 {pay_deadline}</div>
          </OrderExtraPaymentInfo>
        );
      }
    } else if (order.payment_subtype === Cart.PAYMENT_SUBTYPE.offline) {
      const config = getOutlet('config').getValue() || {};
      return (
        <OrderExtraPaymentInfo>
          <div>銀行代碼 {config.offline_bank_code}</div>
          <div>帳號 {config.offline_bank_account}</div>
          <div>戶名 {config.offline_bank_account_name}</div>
        </OrderExtraPaymentInfo>
      );
    }
    return null;
  }

  function renderExtraLogisticsInfo(order) {
    if (order.logistics_type === 'SELF_PICK') {
      return (
        <LogisticsInfo>
          <h3>自取地點：</h3>
          {ConfigOutlet.getValue().self_pick_address}
        </LogisticsInfo>
      );
    }

    return (
      <>
        {order.logistics_type === 'OVERSEA' && (
          <OverseaLogisticsInfo
            logistics_subtype={order.logistics_subtype}
            getExtraData={() => order.extra_data}
            disabled={true}
          />
        )}
        <LogisticsTrackingButton order={order} />
      </>
    );
  }

  return {
    enableInvoiceFeature: true,
    fetchFromCheckout: true,
    renderCustomComponent,
    renderExtraPaymentInfo,
    renderExtraLogisticsInfo,
  };
}

export function adminRenderExtraLogisticsInfo(order) {
  return (
    <>
      {order.logistics_type === 'OVERSEA' && (
        <OverseaLogisticsInfo
          logistics_subtype={order.logistics_subtype}
          getExtraData={() => order.extra_data}
          disabled={true}
        />
      )}
    </>
  );
}

const OrderExtraPaymentInfo = styled.div`
  padding: 15px 20px;
  margin-bottom: 15px;
  background-color: #eee;
  border-radius: 8px;

  & > div {
    line-height: 2;
  }
`;

const LogisticsInfo = styled.div`
  padding-bottom: 10px;
`;
